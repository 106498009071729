class Task {
  static defaultValues = {
    lfdnr: 0,
    firm: 1,
    title: '',
    dueDate: '',
    status: {},
    assignedUsers: [],
    assignedWorkGroups: [],
    creationDate: '',
    creationUser: '',
    objects: [],
    comments: [],
    history: [],
    description: '',
    notificationsActive: false
  }

  constructor() {
    Object.assign(this, JSON.parse(JSON.stringify(Task.defaultValues)))
  }
}

export { Task as TaskClass }
